

























































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  Configuration,
  RedCapital,
  SelectedCountry,
  TitleTemplatePublic
} from "@/configuration";
import API from "@/api";
import { EventBus } from "@/main";
import CurrencyInput from "@/components/Currency.vue";
import DniPerson from "@/components/DniField.vue";
import DniBusiness from "@/components/DniFieldBusiness.vue";
import { isRutValid } from "@/utils";
import { mapState } from "vuex";
import Axios from "axios";

function cleanValue(value: any) {
  return value.toString().replace(/[.|,]/gi, "");
}
@Component({
    data() {
      return {
        e1: 1,
        numeroactividad:0,
        insert1:null,
        insert2:null,
        Total:0,
        siguiente:true,
        dialog:false,
        dialog2:false,
        dialog3:false,
        dialog4:false,
         actividades:[41,42,43,44],
            respuesta:[],
            realizados1:[],
            r1:[41,42,43,44],
            result1:[],
        FormOne:{
            answers: [
                {
                    user_id:  this.$store.state.user.id,
                    cta_answer_id: "411",
                    respuesta: null
                },{
                    user_id:  this.$store.state.user.id,
                    cta_answer_id: "412",
                    respuesta: null
                }
            ]
        },
        FormTwo:{
           answers: [
                {
                    user_id:  this.$store.state.user.id,
                    cta_answer_id: "421",
                    respuesta: null
                }
            ]
        },
        FormThree:{
             answers: [
                {
                    user_id:  this.$store.state.user.id,
                    cta_answer_id: "431",
                    respuesta: null
                }
            ]
        },
        rules: {
            notEmpty: [
                (v: any) => !!v || "Ingrese un valor"
                ],
            phone: [
            (v: any) => !(/[^\d]/gim.test(v)) || 'Tiene que ser solo numeros',
            (v: any) => !!v || 'Teléfono es requerido',
            ],
            }
        }
    },watch:{
        "insert1"(v){
           /* let valor =  parseFloat(this.$data.insert1.toLocaleString('es-CL'))
            this.$data.insert1 = Number(cleanValue(this.$data.insert1)).toLocaleString();
            console.log('join',(v.split(".").join()).toString())*/
            this.$data.Total = (v * this.$data.insert2) / 100;
        },
        "insert2"(){
            this.$data.Total = (this.$data.insert1 * this.$data.insert2) / 100;
        }

    },methods:{
        gosabermas(){
            this.$router.push({
                path: "/actividad/sabermas"
            })
        },
        cerrarmodal(modal){
            if(modal == 1){
                this.$data.dialog1 = false
            }else if(modal == 2){
                this.$data.dialog2 = false
            }
            else if(modal == 3){
                this.$data.dialog3 = false
            }
            else if(modal == 4){
                this.$data.dialog4 = false
            }
        },
        verbeneficios(numbeneficio){
            this.$data.dialog4 = true 
        },
        vermodalmembresias(numeromodalmembresia){
            this.$data.dialog3 = true
        },
        proximostep(){
            //this.$data.e1 += step;
            const registrar = new Promise((resolve,reject)=>{
                setTimeout(()=>{
                    resolve('datos registrados')
                },1000)
            })
            registrar
            .then((mensaje:any)=> {
                console.log(mensaje)
                 this.$data.dialog = true
                 EventBus.$emit("snack-success", "Datos registrados correctamente");
                })
            .catch(err => console.warn(err))
        },
        calcular(){
            this.$data.FormOne.answers[0].respuesta= this.$data.insert1;
            this.$data.FormOne.answers[1].respuesta= this.$data.insert2;

              this.$data.FormOne.answers.forEach((elem:any,index:number)=>{
                //  elem= parseInt(elem[index].respuesta)
                  console.log(elem['respuesta'])
                  elem['respuesta'] = parseInt(elem['respuesta'])
              })
              console.log(this.$data.FormOne)
            API.actividad.gestionadeuda.calcular(this.$data.FormOne)
                .then(resolve=>{
                    //@ts-ignore
                        this.obtenerdatos()
                        .then((res:any) =>{
                             this.$data.numeroactividad = 1;
                            EventBus.$emit("snack-success", "Datos registrados correctamente");
                            if(res.length == 3){
                                this.$data.dialog2 = true
                            }else{
                                if(res.indexOf(42) > -1 ){
                                    this.$data.siguiente=false;
                                    this.$data.dialog = true
                                }else{
                                    this.$data.siguiente=true;
                                    this.$data.dialog = true
                                }
                            }
                        })
                }).catch(error => {console.log(error)})
        },
        capacidadpago(){
            if(this.$data.FormTwo.answers[0].respuesta.length >= 50){
                API.actividad.gestionadeuda.capacidadpago(this.$data.FormTwo)
                    .then(resolve=>{
                    //@ts-ignore
                                this.obtenerdatos()
                                .then((res:any) =>{
                                     this.$data.numeroactividad = 2;
                                    EventBus.$emit("snack-success", "Datos registrados correctamente");
                                    if(res.length == 3){
                                        this.$data.dialog2 = true
                                    }else{
                                        if(res.indexOf(43) > -1 ){
                                            this.$data.siguiente=false;
                                            this.$data.dialog = true
                                        }else{
                                            this.$data.siguiente=true;
                                            this.$data.dialog = true
                                        }
                                    }
                                })
                    })
            }else{
                let total = 50 - this.$data.FormTwo.answers[0].respuesta.length
                EventBus.$emit("snack-error", `Faltan ${total} caracteres`);
            }
           
           
        },
        vendescredito(){
            console.log(this.$data.FormThree.answers[0].respuesta.length)
            if(this.$data.FormThree.answers[0].respuesta.length >= 50 ){
                API.actividad.gestionadeuda.capacidadpago(this.$data.FormThree)
                    .then(resolve=>{
                        //@ts-ignore
                                this.obtenerdatos()
                                .then((res:any) =>{
                                     this.$data.numeroactividad = 3;
                                    EventBus.$emit("snack-success", "Datos registrados correctamente");
                                    if(res.length == 3){
                                        this.$data.dialog2 = true
                                    }else{
                                        if(res.indexOf(43) > -1 ){
                                            this.$data.siguiente=false;
                                            this.$data.dialog = true
                                        }else{
                                            this.$data.siguiente=true;
                                            this.$data.dialog = true
                                        }
                                    }
                                })
                    })
            }else {
                let total = 50 - this.$data.FormThree.answers[0].respuesta.length
                EventBus.$emit("snack-error", `Faltan ${total} caracteres`);
            }
            
        }   
        ,godashboard(){
            this.$router.push({
                path: "/actividad/administracion"
            })
        },
        next(){
            setTimeout(()=>{
                    this.$data.dialog = false
                    this.$data.e1 += 1;
                },1000)
            
        },obtenerdatos(){
           return new Promise((resol,reje)=>{
               API.actividad.infoactividades.actividadesrealizadas(this.$store.state.user.id)
                .then((resolve:any)=>{
                    for(let i=0; i < this.$data.actividades.length ;i++){
                        for(let j=0; j < resolve.data.data.length ;j++){
                            if(this.$data.actividades[i]===resolve.data.data[j].cta_list_id){
                                if((this.$data.actividades[i] < 45) && (this.$data.actividades[i] > 35)){
                                    this.$data.realizados1.push(this.$data.actividades[i])
                                }
                            }
                        }
                    }
                    resol(this.$data.realizados1 = Array.from(new Set(this.$data.realizados1)))      
                }).catch(error => console.log(error))
           })
        }
    },async beforeMount(){
        let etapa = this.$route.params.etapa;
        if (this.$route.params.etapa != null){
            this.$data.e1 = this.$route.params.etapa
        }
        API.actividad.infoactividades.actividadesrealizadas(this.$store.state.user.id)
                .then((resolve:any)=>{
                    for(let i=0; i < this.$data.actividades.length ;i++){
                        for(let j=0; j < resolve.data.data.length ;j++){
                            if(this.$data.actividades[i]===resolve.data.data[j].cta_list_id){
                                if((this.$data.actividades[i] < 45) && (this.$data.actividades[i] > 35)){
                                    this.$data.realizados1.push(this.$data.actividades[i])
                                }
                            }
                        }
                    }
                    console.log(this.$data.realizados1)
                    this.$data.realizados1 = Array.from(new Set(this.$data.realizados1))
                }).catch(error => console.log(error))
        }
  
})
export default class OrganizaTusFinanzas extends Vue {}
